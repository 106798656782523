export const getCurrentTime = () => {
  const currentDate = new Date()
  let datetime =
    currentDate.getDate() +
    "/" +
    (currentDate.getMonth() + 1) +
    "/" +
    currentDate.getFullYear() +
    "@" +
    currentDate.getHours() +
    ":" +
    currentDate.getMinutes() +
    ":" +
    currentDate.getSeconds()

  return datetime
}

export const displayMessageTime = dateTime => {
  const currentDate = new Date()
  let dateTimeArray = dateTime.split("@")
  let dateArray = dateTimeArray[0].split("/")
  let timeArray = dateTimeArray[1].split(":")
  if (
    currentDate.getDate() == dateArray[0] &&
    currentDate.getMonth() + 1 == dateArray[1] &&
    currentDate.getFullYear() == dateArray[2]
  ) {
    return timeArray[0] + ":" + timeArray[1]
  } else {
    return dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2]
  }
}
