import React, { useState } from "react"
import { Container } from "./AttendEvent.styles"
import Location from "../../assets/icons/location-drop.svg"
import { addDoc, collection, getDocs, query, where } from "firebase/firestore"
import { db } from "../../firebase/config"
import { useAuthContext } from "../../hooks/useAuthContext"
import { getCurrentTime } from "../../helpers/timeManipulation"

const AttendEvents = ({ data, eventId,arabic }) => {
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")

  const date = data.date
  const { user } = useAuthContext()

  
  
    const day = date.toDate().getdate
    const monthName = date.toDate().toLocaleString('en-US', {month: 'short'})
  

  // handling for submit
  const handleSubmit = e => {
    e.preventDefault()

    // checking to see if subject and message is found
    if (!subject || !message) {
      alert("Please fill all required fields")
      return
    }

    // checking if person is messaging himself
    if (user.uid === data.added_by) {
      alert("Cannot attend an event you created")
      return
    }

    // checking if this kind of document already exists
    const q = query(
      collection(db, "inbox"),
      where("activeUsers", "array-contains", user.uid)
    )
    getDocs(q).then(document => {
      if (document.docs.length !== 0) {
        const length = document.docs.length
        let count = 0

        // looping
        document.forEach(doc => {
          count++

          // checking if id exists
          if (
            doc.data().eventId === eventId &&
            doc.data().activeUsers.includes(data.added_by)
          ) {
            alert(
              "You already have a chat with seller. Please check your inbox"
            )
            return
          }

          // checking when forEach loop will end
          if (count === length) {
            addDocument()
          }
        })
      } else {
        addDocument()
      }
    })
  }

  // adding the document to firebase as a start of the chat
  const addDocument = () => {
    const datetime = getCurrentTime()
    addDoc(collection(db, "inbox"), {
      subject,
      messages: [
        {
          timestamp: datetime,
          content: message,
          userId: user.uid,
        },
      ],
      eventId: eventId,
      users: [user.uid, data.added_by],
      activeUsers: [user.uid, data.added_by],
    }).then(() => {
      alert("Message sent successfully")
      setSubject("")
      setMessage("")
    })
  }

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-container">
            <div className="img-container">
              <img
                src={data.thumbnail.url}
                alt="villa picture"
                className="event-img"
              />
              <div className="date">
                <span className="day">{day}</span>
                <span className="month">{monthName}</span>
              </div>
            </div>

            <h4>{data.name}</h4>

            <div className="info">
              <img src={Location} alt="location icon" />
              <div className="description grey-color">
                {data.address}, {data.country.label}
              </div>
            </div>

            <p className="grey-color">{data.topic}</p>
          </div>
          <div className="col-lg-6 form-container">
            <h4>
              <b>{arabic==false?'Contact the agent':'اتصل بالوكيل'}</b>
            </h4>
            <p className="grey-color">
              {arabic==false?'Please send a message to the agent in order to attend the event':'يرجى إرسال رسالة إلى الوكيل من أجل حضور الحدث'}
            </p>
            <form onSubmit={handleSubmit}>
              <label htmlFor="subject">{arabic==false ?'SUBJECT*':'موضوعات*'}</label>
              <input
                type="text"
                id="subject"
                value={subject}
                name="attend_message"
                placeholder={arabic==false?"Enter Subject":"أدخل الموضوع"}
                onChange={e => setSubject(e.target.value)}
              ></input>
              <label htmlFor="message">{arabic==false?'MESSAGE*':'رسالة*'}</label>
              <textarea
                id="message"
                value={message}
                onChange={e => setMessage(e.target.value)}
                name="attend_message"
                rows={4}
                placeholder={arabic==false?"Enter Message":"أدخل رسالة"}
              ></textarea>
              <button type="submit">{arabic==false?'SUBMIT':'إرسال'}</button>
            </form>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default AttendEvents
