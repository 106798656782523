import styled from "styled-components"

export const Container = styled.div`
  .grey-color {
    color: #7e7e7e;
  }
  padding: 70px 0 140px;

  @media (max-width: 992px) {
    padding: 30px 0;
  }

  .text-container {
    background-color: rgba(143, 143, 143, 0.1);
    padding-right: 175px;
    padding-top: 50px;
    padding-left: 30px;
    padding-bottom: 60px;
    h4 {
      padding-top: 10px;
    }
    p {
      padding-top: 10px;
    }
    .info {
      display: flex;
      padding-top: 10px;
      .description {
        margin-left: 10px;
      }
    }
    .img-container {
      position: relative;

      .event-img {
        width: 100%;
      }

      .date {
        background-color: rgba(29, 140, 195, 0.8);
        text-align: center;
        position: absolute;
        bottom: 10px;
        left: 10px;
        padding: 10px 25px;
        color: white;

        .day {
          display: block;
        }
      }
    }
  }

  .form-container {
    position: relative;
    left: -73px;
    bottom: -53px;
    background-color: white;
    height: 580px;
    h4 {
      padding-top: 25px;
    }
    p {
      padding-top: 15px;
    }
    button {
      border: none;
      font-size: 0.77rem;
      background-color: var(--lightGreen);
      padding: 1rem 2rem;
      text-align: center;
      color: white;
      border-radius: 5px;
      text-decoration: none;
    }
    label {
      padding-top: 30px;
    }
    input {
      margin-top: 10px;
      border: 1px solid #dddddd;
      border-radius: 5px;
      height: 55px;
      width: 100%;
      padding-left: 10px;
      &:focus {
        outline: none;
      }
    }
    textarea {
      margin-top: 10px;
      border: 1px solid #dddddd;
      border-radius: 5px;
      width: 100%;
      height: 150px;
      padding-left: 10px;
      &:focus {
        outline: none;
      }
    }
    @media (max-width: 992px) {
      padding-left: 36px;
      padding-bottom: 36px;
      position: unset;
      height: auto;
      background-color: white;
    }
    .green-btn {
      margin-top: 42px;
      width: 266px;
      float: right;
    }
  }
`
