import React from 'react'
import { Link } from 'gatsby'

import { ContainerAttendEvent } from './Hero.styles'



//icons
import ArrowBack from '../../assets/icons/arrow-back.svg'



export default function AttendEventHero  ({arabic}){
  return (
    <ContainerAttendEvent>
        <div className="container">
            <div className="row">
                <div className="col-lg-6 text-container">
                    <Link to='/event-detail'>
                        <img src={ArrowBack} alt="arrow back" />
                        <span>{arabic===false?'BACK TO EVENT DETAILS':'عودة إلى تفاصيل الحدث'}</span>
                    </Link>
                    <h1>{arabic===false?'Attend the event':'عودة إلى تفاصيل الحدث'}</h1>
                </div>  
            </div>
        </div>    
    </ContainerAttendEvent>
  )
}


